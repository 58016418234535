export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]*$/

interface ContactData {
  company: string
  email: string
  extension: string
  firstName: string
  lastName: string
  orgUnit: string
  persNumber: string
  receiverType: string
  salutation: string
  salutationText: string
  telNumber: string
}

interface ResponseData {
  nrRecords: number
  queEntryId: number
  returnCode: string
  routings: ContactData[]
  sqlCode: number
}

export const FORM_CONTACT_COUNTRY_KEY = 'containexFormCountry'
export const FORM_UUID_KEY = 'containexFormUUID'
const REFERRER_STORAGE_KEY = 'containexReferrer'
const FORM_REFERRER_STORAGE_KEY = 'containexFormReferrer'
const FORM_INTRO_REFERRER_STORAGE_KEY = 'containexFormIntroReferrer'

const getURLParameters = () => {
  const parameters: { [key: string]: string }[] = []
  const pageUrl = location.href
  const parametersString = pageUrl.split('?')[1]
  if (parametersString) {
    const parametersArray = parametersString.split('&')
    parametersArray.forEach((keyValuePair) => {
      const splitKeyValuePair = keyValuePair.split('=')
      const key = splitKeyValuePair[0]
      const value = splitKeyValuePair[1]
      parameters.push({ [key]: value })
    })
  }
  return parameters
}

const getDomain = (url) => {
  return url.match(/:\/\/(.[^/]+)/)[1]
}

export const getSessionReferrer = () => {
  return sessionStorage.getItem(REFERRER_STORAGE_KEY)
}

export const saveReferrer = () => {
  const urlParameters = getURLParameters()
  let referrer = sessionStorage.getItem(REFERRER_STORAGE_KEY)

  if (urlParameters) {
    let utm_source, utm_medium, utm_campaign
    urlParameters.forEach((parameter) => {
      if (parameter.gclid) {
        referrer = `source=www.google.com, medium=cpc, gclid=${parameter.gclid}`
      }
      if (parameter.utm_source) {
        utm_source = parameter.utm_source
      }
      if (parameter.utm_medium) {
        utm_medium = parameter.utm_medium
      }
      if (parameter.utm_campaign) {
        utm_campaign = parameter.utm_campaign
      }
    })

    if (utm_source || utm_medium || utm_campaign) {
      const utmArray: string[] = []
      if (utm_campaign) {
        utmArray.push(`utm_campaign=${utm_campaign}`)
      }
      if (utm_medium) {
        utmArray.push(`utm_medium=${utm_medium}`)
      }
      if (utm_source) {
        utmArray.push(`utm_source=${utm_source}`)
      }
      referrer = utmArray.join('&')
    }
  }

  if ((!referrer || referrer.length === 0) && document.referrer) {
    const currentDomain = getDomain(location.href)
    const referrerDomain = getDomain(document.referrer)
    if (referrerDomain !== currentDomain) {
      referrer = `source=${referrerDomain}, medium=referral`
    }
  }

  sessionStorage.setItem(REFERRER_STORAGE_KEY, !referrer ? '' : referrer)
}

export const saveFormReferrer = (override?: string) => {
  if (override) {
    sessionStorage.setItem(FORM_REFERRER_STORAGE_KEY, override)
  } else if (document.referrer && document.referrer !== '') {
    sessionStorage.setItem(FORM_REFERRER_STORAGE_KEY, document.referrer)
  } else {
    sessionStorage.setItem(FORM_REFERRER_STORAGE_KEY, document.location.href)
  }
}

export const saveIntroReferrer = () => {
  if (document.referrer) {
    sessionStorage.setItem(FORM_INTRO_REFERRER_STORAGE_KEY, document.referrer)
  }
}

export const deleteSavedReferrer = () => {
  sessionStorage.removeItem(FORM_REFERRER_STORAGE_KEY)
}

export const deleteSavedIntroReferrer = () => {
  sessionStorage.removeItem(FORM_INTRO_REFERRER_STORAGE_KEY)
}

export const getSavedFormReferrer = () => {
  return sessionStorage.getItem(FORM_REFERRER_STORAGE_KEY) || document.location.href 
}

export const getSavedIntroReferrer = () => {
  return sessionStorage.getItem(FORM_INTRO_REFERRER_STORAGE_KEY) || ''
}

export const redirectToFormSuccessPage = (responseData?: ResponseData) => {
  const { successPageUrl } = window.containex.data
  if (responseData && typeof responseData === 'string') {
    const htmlResponse = responseData as string
    const supportID = htmlResponse.match(
      /support\sID\sis\s(\d+)./,
    )?.[1] as string
    if (supportID) {
      throw new Error(`Support-ID ${supportID}`)
    }
    throw new Error()
  }
  if (responseData?.routings?.[0]) {
    sessionStorage.setItem(
      'containexContactData',
      JSON.stringify(responseData?.routings?.[0]),
    )
  } else {
    sessionStorage.removeItem('containexContactData')
  }
  location.href = successPageUrl
    ? successPageUrl
    : `${location.href.replace(location.search, '')}/success`
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const getFormConfig = () => window['containex']?.data?.form
