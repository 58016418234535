document.addEventListener('DOMContentLoaded', async () => {
  await import('lightgallery.js')
  document
    .querySelectorAll<HTMLDivElement>('.ProductGridRental')
    .forEach(async (productGrid) => {
      await import('lightgallery.js')
      let galleryInstance

      const createLightGallery = (productGrid: HTMLElement) => {
        if (galleryInstance) {
          galleryInstance.destroy()
        }
        galleryInstance = window.lightGallery(productGrid, {
          selector: '[data-src]',
          exThumbImage: 'data-thumb-img',
        })
      }

      createLightGallery(productGrid)

      const outsideBtn = productGrid.querySelector(
        '.image-switch__button--outside',
      )
      const insideBtn = productGrid.querySelector(
        '.image-switch__button--inside',
      )

      const swapSources = (productGrid: HTMLElement) => {
        const imageElems = productGrid.querySelectorAll('[data-srcset]')
        imageElems.forEach((imageElem) => {
          const currentSrc = imageElem?.getAttribute('data-srcset')
          const nextSrc = imageElem?.getAttribute('data-alt-srcset')
          const currentThumbSrc = imageElem?.getAttribute('data-thumb-img')
          const nextThumbSrc = imageElem?.getAttribute('data-alt-thumb-img')
          if (currentSrc && nextSrc) {
            imageElem?.setAttribute('data-srcset', nextSrc)
            imageElem?.setAttribute('data-alt-srcset', currentSrc)
          }
          if (currentThumbSrc && nextThumbSrc) {
            imageElem?.setAttribute('data-thumb-img', nextThumbSrc)
            imageElem?.setAttribute('data-alt-thumb-img', currentThumbSrc)
          }
        })
      }

      outsideBtn?.addEventListener('click', () => {
        outsideBtn.classList.add('image-switch__button--active')
        insideBtn?.classList.remove('image-switch__button--active')
        productGrid.classList.remove('ProductGridRental--show-sketch')
        productGrid.querySelectorAll('[data-src]').forEach((lightBoxImg) => {
          lightBoxImg.setAttribute(
            'data-src',
            lightBoxImg.getAttribute('data-ori-src')!,
          )
        })
        swapSources(productGrid)
        createLightGallery(productGrid)
      })

      insideBtn?.addEventListener('click', () => {
        insideBtn.classList.add('image-switch__button--active')
        outsideBtn?.classList.remove('image-switch__button--active')
        productGrid.classList.add('ProductGridRental--show-sketch')
        productGrid.querySelectorAll('[data-src]').forEach((lightBoxImg) => {
          lightBoxImg.setAttribute(
            'data-src',
            lightBoxImg.getAttribute('data-alt-src')!,
          )
        })
        swapSources(productGrid)
        createLightGallery(productGrid)
      })
    })

  document.querySelectorAll('.LineComparison').forEach((component) => {
    const outsideBtn = component.querySelector('.image-switch__button--outside')
    const insideBtn = component.querySelector('.image-switch__button--inside')
    
    outsideBtn?.addEventListener('click', () => {
      outsideBtn.classList.add('image-switch__button--active')
      insideBtn?.classList.remove('image-switch__button--active')
      component.classList.remove('LineComparison--show-inside')
    })

    insideBtn?.addEventListener('click', () => {
      insideBtn.classList.add('image-switch__button--active')
      outsideBtn?.classList.remove('image-switch__button--active')
      component.classList.add('LineComparison--show-inside')
    })
    setTimeout(() => {
    insideBtn?.classList.add('image-switch__button--active')
    outsideBtn?.classList.remove('image-switch__button--active')
    component.classList.add('LineComparison--show-inside')   
    }, 1000)
    setTimeout(() => {
      outsideBtn?.classList.add('image-switch__button--active')
      insideBtn?.classList.remove('image-switch__button--active')
      component.classList.remove('LineComparison--show-inside')
    }, 2000)    
    })
  })
