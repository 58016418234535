document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('scroll', () => {
    const images = [
      document.querySelectorAll(
        '.TeaserFullWidth:not(.TeaserFullWidth--reversed) img',
      ),
      document.querySelectorAll(
        '.TeaserFullWidth.TeaserFullWidth--reversed img',
      ),
    ]
    const windowHeight = window.innerHeight
    images.forEach((set, index) => {
      set.forEach((img) => {
        if (window.innerWidth < 991) {
          (img as HTMLImageElement).style.objectPosition = `center`
          return
        }
        const imageDistance = img.getBoundingClientRect().top
        let imgOffset = ((windowHeight - imageDistance) / windowHeight) * 75
        if (index === 1) {
          imgOffset = 100 - imgOffset
        }
        (img as HTMLImageElement).style.objectPosition = `-${imgOffset}px`
      })
    })
  })
})
