document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-collapse-trigger]').forEach((trigger) => {
    trigger.addEventListener('click', () => {
      const footerColumn = trigger.closest('.Footer__column')!
      const collapsible = footerColumn.querySelector('.Footer__section')!
      const isCollapsed = collapsible.classList.contains(
        'Footer__section--mobile-collapsed',
      )
      collapsible.classList[isCollapsed ? 'remove' : 'add'](
        'Footer__section--mobile-collapsed',
      )
    })
  })
})
