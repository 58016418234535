const buildEllipsis = () => {
  document.querySelectorAll('[data-max-lines]').forEach( async (element) => {
    const { default: MultiClamp } = await import('multi-clamp')
    const maxLines = element.getAttribute('data-max-lines')?.split(',')!
    const lines =
      maxLines.length === 1
        ? maxLines[0]
        : window.innerWidth < 992
        ? maxLines[0]
        : maxLines[1]

    new MultiClamp(element, {
      ellipsis: '...',
      clamp: Number(lines),
    })
  })
}

document.addEventListener('DOMContentLoaded', () => {
  buildEllipsis()
  window.addEventListener('resize', buildEllipsis)
})
