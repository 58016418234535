window.addEventListener('oneTrustInitialized', () => {
  const cookieDialog = document.querySelector<HTMLElement>(
    '#onetrust-policy-text',
  )

  if (cookieDialog) {
    const getImprintHrefs = document
      .querySelector('[data-imprint-url')
      ?.getAttribute('data-imprint-url')
    const getPrivacyPolicyHrefs = document
      .querySelector('[data-privacy-url')
      ?.getAttribute('data-privacy-url')

    cookieDialog
      .querySelector('a[data-tracking-intern="datenschutz"]')
      ?.setAttribute('href', getPrivacyPolicyHrefs!)
    cookieDialog
      .querySelector('a[data-tracking-intern="impressum"]')
      ?.setAttribute('href', getImprintHrefs!)
  }
})
