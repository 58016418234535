const PREFERRED_LANG_COOKIE = 'preferred_language'
const HIDE_REGION_PROMPT_COOKIE = 'hide_region_prompt'

const regionPromptHeaderClass = 'Header--has-region-prompt'

const addRegionPromptClassToHeader = (regionPrompt: HTMLElement) =>
  regionPrompt.closest('.Header')?.classList.add(regionPromptHeaderClass)

const removeRegionPromptClassFromHeader = (regionPrompt: HTMLElement) =>
  regionPrompt.closest('.Header')?.classList.remove(regionPromptHeaderClass)

document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  const { default: Cookies } = await import('js-cookie')
  document
    .querySelectorAll('.RegionPrompt')
    .forEach((rp) => addRegionPromptClassToHeader(rp as HTMLElement))
  on('click', '.js-region-prompt-hide', (e) => {
    e.preventDefault()
    document.querySelectorAll('.RegionPrompt').forEach((rp) => {
      rp.classList.add('RegionPrompt--hidden')
      removeRegionPromptClassFromHeader(rp as HTMLElement)
    })
    document
      .querySelector('.region-prompt-spacing')
      ?.classList.remove('region-prompt-spacing')
    Cookies.set(HIDE_REGION_PROMPT_COOKIE, 'true', {
      expires: 30 * 24 * 60 * 60, // 30 days
      sameSite: 'Lax'
    })
  })
  on('click', '.js-region-prompt-select', (e) => {
    Cookies.set(HIDE_REGION_PROMPT_COOKIE, 'true', {
      expires: 30 * 24 * 60 * 60, // 30 days
      sameSite: 'Lax'
    })
    Cookies.set(
      PREFERRED_LANG_COOKIE,
      (e.currentTarget as HTMLElement).getAttribute('data-locale'),
      {
        expires: 30 * 24 * 60 * 60, // 30 days
        sameSite: 'Lax'
      },
    )

    const languageUrl = ((e.currentTarget as HTMLElement).getAttribute('href') || e.currentTarget.getAttribute('data-switch-lang'))!

    window.location.href = languageUrl
  })
})

document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  const { default: Cookies } = await import('js-cookie')
  on('click', '.js-choose-language', (e) => {
    Cookies.set(HIDE_REGION_PROMPT_COOKIE, 'true', {
      expires: 30 * 24 * 60 * 60, // 30 days
      sameSite: 'Lax'
    })
    Cookies.set(
      PREFERRED_LANG_COOKIE,
      (e.currentTarget as HTMLElement).getAttribute('data-locale'),
      {
        expires: 30 * 24 * 60 * 60, // 30 days
        sameSite: 'Lax'
      },
    )
  })
  on('click', '.js-scroll-to-languages', (e) => {
    e.preventDefault()
    document.querySelector('.language-selection')!.scrollIntoView({
      behavior: 'smooth',
    })
  })
})
