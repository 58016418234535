document.addEventListener('DOMContentLoaded', () => {
  const currentPath = location.pathname
  document.querySelectorAll('.js-main-nav-link').forEach((link) => {
    const href = link.getAttribute('href')
    if (href && currentPath.includes(href)) {
      link.classList.add('active')
    }
  })
})

// Make header compact on scroll
document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('Header')!

  if (!header) {
    return
  }

  const scrollHandler = () => {
    const compactClass = 'Header--compact'
    if (header.classList.contains(compactClass) && window.scrollY <= 200) {
      header.classList.remove(compactClass)
    } else if (
      !header.classList.contains(compactClass) &&
      window.scrollY > 200
    ) {
      header.classList.add(compactClass)
    }
  }

  window.addEventListener('scroll', scrollHandler)
  scrollHandler()
})

// Make header compact on scroll
document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  on('click', '.js-contact-overlay-trigger', (e) => {
    e.preventDefault()
    document
      .querySelector('.ContactOverlay')!
      .classList.add('ContactOverlay--active')
  })

  on('click', '.js-contact-overlay-close', (e) => {
    e.preventDefault()
    document
      .querySelector('.ContactOverlay')!
      .classList.remove('ContactOverlay--active')
  })
})

document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  on('click', '[data-menu-trigger]', () => {
    const nav = document.querySelector('.Navigation--mobile') as HTMLElement
    nav.setAttribute('data-level', '0')
    nav.style.marginTop = `${
      document.querySelector('.Header--mobile')!.clientHeight - 50
    }px`
    nav.classList.toggle('Navigation--open')
    const header = document.querySelector('.Header--mobile')!
    header.classList.toggle('Header--nav-open')
  })
})

document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  on('click', '.js-open-language-select', () => {
    document
      .querySelector('.RegionList')!
      .classList.remove('RegionList--hidden')
  })

  on('click', '.js-close-language-select', () => {
    document.querySelector('.RegionList')!.classList.add('RegionList--hidden')
  })

  on('click', '.js-open-search', () => {
    window.openSearch()
  })
  
})

// Flyout
document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  let isTouch = false
  const body = document.querySelector('body')!
  on('touchstart', '.Header__dimmer', () => {
    body.classList.remove('nav-open')
    const openNav = document.querySelector('.Navigation__flap--open')
    if (openNav) {
      openNav.classList.remove('Navigation__flap--open')
    }
  })

  // don't execute link click when tapping on a touch device
  on('click', '.js-main-nav-link', (e) => {
    const flyout = (e.target as HTMLElement).parentElement!.querySelector(
      '.js-main-nav-flyout .Navigation__flap__navigation-items',
    ) as HTMLElement
    if (isTouch && flyout) {
      e.preventDefault()
      e.stopPropagation()
    }
  })

  on('touchstart', '.Navigation__flap--open a', () => {
    document.querySelector('.')?.classList.remove('Navigation__flap--open')
  })

  on('touchstart', '.js-main-nav-link', (e) => {
    isTouch = true
    body.classList.toggle('nav-open')
    const flyout = (e.target as HTMLElement).parentElement!.querySelector(
      '.js-main-nav-flyout',
    ) as HTMLElement
    flyout.classList[
      flyout.classList.contains('Navigation__flap--open') ? 'remove' : 'add'
    ]('Navigation__flap--open')
  })

  document.querySelectorAll('.js-main-nav-link').forEach((item) => {
    const li = item.closest('li')!
    if (
      li.querySelector(
        '.js-main-nav-flyout .Navigation__flap__navigation-items',
      )
    ) {
      li.addEventListener('mouseenter', () => {
        body.classList.add('nav-open')
        item.classList.add('hover-active')
        li.classList.add('hover-active')
      })
      li.addEventListener('mouseleave', () => {
        body.classList.remove('nav-open')
        item.classList.remove('hover-active')
        li.classList.remove('hover-active')
      })
    }
  })
  document.querySelectorAll('.js-main-nav-link').forEach((item) => {
    const li = item.closest('li')!
    if (
      li
        .querySelector(
          '.js-main-nav-flyout .Navigation__flap__navigation-items',
        )
        ?.hasChildNodes()
    ) {
      item.addEventListener('focus', () => {
        item.addEventListener('click', (event) => {
          event.stopPropagation()
          const target = <PointerEvent>event
          if (target.pointerType !== 'mouse') {
            if (item.getAttribute('aria-expanded') === 'false') {
              event.preventDefault()
              body.classList.add('nav-open')
              item.classList.add('hover-active')
              item.setAttribute('aria-expanded', 'true')
              li.classList.add('hover-active')
            }
          }
        })
      })
      li.addEventListener('focusout', (event) => {
        const target = <Node>event.relatedTarget
        if (!li.contains(target)) {
          li.classList.remove('hover-active')
          item.classList.remove('hover-active')
          item.setAttribute('aria-expanded', 'false')
          body.classList.remove('nav-open')
        }
      })
    }
  })

  document.querySelectorAll('.js-nav-item').forEach((navItem) => {
    const flyout = navItem.closest('.js-main-nav-flyout')
    let blockClose = false

    const closeSubNav = () => {
      if (!blockClose) {
        flyout?.querySelectorAll('.SubNav--open').forEach((item) => {
          item.classList.remove('SubNav--open')
          item.setAttribute('aria-expanded', 'false')
        })
        flyout?.querySelectorAll('.NavigationItem--open').forEach((item) => {
          item.classList.remove('NavigationItem--open')
          item.setAttribute('aria-expanded', 'false')
        })
      }
    }

    flyout
      ?.querySelector('.Navigation__flap__footer')
      ?.addEventListener('mouseenter', () => {
        blockClose = true
      })

    flyout
      ?.querySelector('.Navigation__flap__footer')
      ?.addEventListener('mouseleave', () => {
        blockClose = false
      })

    flyout?.querySelectorAll('.js-subnav').forEach((subNav) => {
      // when mouse is entering subnav block the mouseleave consequence
      subNav.addEventListener('mouseenter', () => {
        blockClose = true
      })
    })

    navItem.addEventListener('mouseenter', (e) => {
      if (e.currentTarget) {
        blockClose = true
        const ref = (e.currentTarget as HTMLElement).getAttribute(
          'data-nav-item',
        )

        flyout?.querySelectorAll('.SubNav--open').forEach((item) => {
          item.classList.remove('SubNav--open')
          item.setAttribute('aria-expanded', 'false')
        })
        flyout?.querySelectorAll('.NavigationItem--open').forEach((item) => {
          item.classList.remove('NavigationItem--open')
          item.setAttribute('aria-expanded', 'false')
        })
        const matchingSubElement = (flyout as HTMLElement).querySelector(
          `[data-nav-item-target="${ref}"]`,
        )

        if (matchingSubElement) {
          const ct = e.currentTarget as HTMLElement
          ct.classList.add('NavigationItem--open')
          matchingSubElement.classList.toggle('SubNav--open')
          matchingSubElement.setAttribute('aria-expanded', 'true')
        }
      }
    })

    navItem.addEventListener('mouseleave', () => {
      setTimeout(closeSubNav, 10) // delayed so it can be blocked if entering subnav
    })

    navItem.addEventListener('click', (e) => {
      const target = <PointerEvent>e
      if (e.currentTarget) {
        blockClose = true
        const ref = (e.currentTarget as HTMLElement).getAttribute(
          'data-nav-item',
        )

        flyout?.querySelectorAll('.SubNav--open').forEach((item) => {
          item.classList.remove('SubNav--open')
        })
        flyout?.querySelectorAll('.NavigationItem--open').forEach((item) => {
          item.classList.remove('NavigationItem--open')
        })
        const matchingSubElement = (flyout as HTMLElement).querySelector(
          `[data-nav-item-target="${ref}"]`,
        ) as HTMLElement

        if (matchingSubElement) {
          const ct = e.currentTarget as HTMLElement
          if (target.pointerType !== 'mouse') {
            if (ct.getAttribute('aria-expanded') === 'false') {
              e.preventDefault()
              ct.classList.add('NavigationItem--open')
              ct.setAttribute('aria-expanded', 'true')
              matchingSubElement.classList.toggle('SubNav--open')
              ct.addEventListener('keydown', (event) => {
                const targetKey = <KeyboardEvent>event
                if (targetKey.code === 'Tab' && targetKey.shiftKey === false) {
                  event.stopPropagation()
                  event.preventDefault()
                  matchingSubElement.querySelector('a')?.focus()
                }
              })
            }
          }
        }
      }
    })

    navItem.addEventListener('focusout', () => {
      setTimeout(closeSubNav, 10) // delayed so it can be blocked if entering subnav
    })
  })
})

// Mobile navigation clicks
document.addEventListener('DOMContentLoaded', () => {
  const subnav = document.querySelectorAll('[data-submenu-trigger]')
  subnav.forEach((navLink) =>
    navLink.addEventListener('click', () => {
      document
        .querySelectorAll('.Navigation__submenu--active')
        .forEach((item) => {
          item.classList.remove('Navigation__submenu--active')
        })
      navLink
        .closest('li')
        ?.querySelector('.Navigation__submenu')
        ?.classList.add('Navigation__submenu--active')
      const navMobile = document.querySelector('.Navigation--mobile')!
      navMobile.setAttribute(
        'data-level',
        navMobile.getAttribute('data-level') === '1' ? '2' : '1',
      )
    }),
  )

  const subsubnav = document.querySelectorAll('[data-sub-submenu-trigger]')
  subsubnav.forEach((navLink) =>
    navLink.addEventListener('click', () => {
      document
        .querySelectorAll('.js-nav-level-2-item .Navigation__submenu--active')
        .forEach((item) => {
          item.classList.remove('Navigation__submenu--active')
        })
      navLink
        .closest('.js-nav-level-2-item')
        ?.querySelector('.Navigation__submenu')
        ?.classList.add('Navigation__submenu--active')
      const navMobile = document.querySelector('.Navigation--mobile')!
      navMobile.setAttribute(
        'data-level',
        navMobile.getAttribute('data-level') === '1' ? '2' : '1',
      )
    }),
  )

  const backButtons = document.querySelectorAll('.Navigation__submenu__back')!
  backButtons.forEach((backButton) => {
    backButton.addEventListener('click', () => {
      const nav = document.querySelector('.Navigation--mobile[data-level]')!
      nav.setAttribute(
        'data-level',
        nav.getAttribute('data-level') === '1' ? '0' : '1',
      )
    })
  })
})

document.addEventListener('DOMContentLoaded', async () => {

  const hasOffersCounter = (document.querySelectorAll('[data-offers-count]')?.length ?? 0) > 0;
  if (hasOffersCounter) {
    let promise
    const { getCulture } = await import('./components/helpers')
    const cachedCountData = JSON.parse(
      sessionStorage.getItem(`ctxCategoryCount_${getCulture()}`) || 'null',
    )
    if (cachedCountData) {
      promise = Promise.resolve({ data: { categories: cachedCountData } })
    } else {
      const { default: Axios } = await import('axios')
      const { BASE_URL } = await import('./components/store')
      promise = Axios.get(`${BASE_URL}/api/offers/get-category-filter`, {
        params: { culture: getCulture() },
      })
    }

    promise.then((res) => {
      sessionStorage.setItem(
        `ctxCategoryCount_${getCulture()}`,
        JSON.stringify(res.data.categories),
      )
      document.querySelectorAll('[data-offers-count]').forEach((element) => {
        const category = element.getAttribute('data-offers-count')
        const count =
          res.data.categories?.find(
            (categoryCount) => categoryCount.id === category,
          )?.count || 0
        element.innerHTML = `${count}`
      })
    })
  }
})
