const scrollToPosition = (top: number) =>
  window.scrollTo({
    top,
    behavior: 'smooth',
  })

document.addEventListener('DOMContentLoaded', async () => {
  const { on } = await import('delegated-events')
  on('click', 'a[href]', (e) => {
    let selector = (e.currentTarget as HTMLElement).getAttribute('href')
    if (selector && selector.includes('#')) {
      selector = selector
        .toLowerCase()
        .replace(location.pathname.toLowerCase(), '')
      const target = document.querySelector(selector) as HTMLElement
      if (target) {
        e.preventDefault()

        const headerOffset =
          window.innerWidth > 1024
            ? document
                .querySelector('.Header--desktop')!
                .getBoundingClientRect().bottom + 25
            : document.querySelector('.Header--mobile')!.getBoundingClientRect()
                .bottom

        let targetPosition = target.offsetTop

        scrollToPosition(target.offsetTop - headerOffset)

        // fallback for when something is changing height while or shortly after scrolling
        const recalculateTarget = setInterval(() => {
          if (target.offsetTop > targetPosition) {
            targetPosition = target.offsetTop
            scrollToPosition(target.offsetTop - headerOffset)
          }
        })

        setTimeout(() => clearInterval(recalculateTarget), 1500)

        history.pushState({}, '', `${window.location.pathname}${window.location.search}${selector}`)
      }
    }
  })
})
