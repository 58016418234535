document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.Gallery').forEach( async (gallery) => {
    await import('lightgallery.js')
    window.lightGallery(gallery, {
      exThumbImage: 'data-thumb-img',
    })

    gallery.addEventListener('onBeforeSlide', () => {
      document
        .querySelector('.lg-outer')
        ?.setAttribute('data-tracking-area', 'imageOverlay')
      document
        .querySelector('.lg-next')
        ?.setAttribute('data-tracking-element', 'nav-next')
      document
        .querySelector('.lg-prev')
        ?.setAttribute('data-tracking-element', 'nav-prev')
    })
  })

  document.querySelectorAll('.ImageGrid').forEach( async (imageGrid) => {
    await import('lightgallery.js')
    window.lightGallery(imageGrid, {
      selector: '[data-src]',
      exThumbImage: 'data-thumb-img',
    })
  })
})
