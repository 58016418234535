const setTabActive = (tabsParent, tabId) => {
  tabsParent
    .querySelector('.Tabs__tab-content--active')
    .classList.remove('Tabs__tab-content--active')

  tabsParent
    .querySelector(`[data-tab-content='${tabId}']`)
    .classList.add('Tabs__tab-content--active')
}

const displayMoreIndicator = (tabsParent) => {
  // @ts-ignore
  const { clientWidth, scrollWidth, scrollLeft } = tabsParent.querySelector(
    '.Tabs__row',
  )

  if (scrollLeft > 0) {
    tabsParent
      .querySelector('.Tabs__swipe-indicator--left')
      .classList.add('Tabs__swipe-indicator--show')
  } else {
    tabsParent
      .querySelector('.Tabs__swipe-indicator--left')
      .classList.remove('Tabs__swipe-indicator--show')
  }

  if (scrollWidth - scrollLeft !== clientWidth) {
    tabsParent
      .querySelector('.Tabs__swipe-indicator--right')
      .classList.add('Tabs__swipe-indicator--show')
  } else {
    tabsParent
      .querySelector('.Tabs__swipe-indicator--right')
      .classList.remove('Tabs__swipe-indicator--show')
  }
}

const adjustIndicator = (tabsParent, tabTrigger) => {
  const { offsetLeft, offsetWidth } = tabTrigger
  const indicator = tabsParent.querySelector('.Tabs__active-indicator')
  // @ts-ignore
  indicator.style.left = `${offsetLeft}px`
  // @ts-ignore
  indicator.style.width = `${offsetWidth}px`
}

const activateTabByHash = () => {
  document.querySelectorAll('.Tabs').forEach((tabsParent) => {
    const urlHash = window.location.hash.substr(1)

    if (urlHash) {
      const tab = tabsParent.querySelector(`[data-tab="${urlHash}"]`)

      if (tab) {
        // @ts-ignore
        setTabActive(tabsParent, urlHash)
        adjustIndicator(tabsParent, tab)
      }
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.Tabs').forEach((tabsParent) => {
    tabsParent.querySelectorAll('[data-tab]').forEach((tabTrigger, index) => {
      window.addEventListener('resize', () => {
        displayMoreIndicator(tabsParent)
      })
      const row = tabsParent.querySelector('.Tabs__row')!
      row.addEventListener('scroll', () => displayMoreIndicator(tabsParent))
      const leftScroll = tabsParent.querySelector(
        '.Tabs__swipe-indicator--left',
      )!
      const rightScroll = tabsParent.querySelector(
        '.Tabs__swipe-indicator--right',
      )!
      leftScroll.addEventListener('click', () => {
        row.scrollBy({ behavior: 'smooth', left: -50 })
      })
      rightScroll.addEventListener('click', () => {
        row.scrollBy({ behavior: 'smooth', left: 50 })
      })
      displayMoreIndicator(tabsParent)

      if (index === 0) {
        adjustIndicator(tabsParent, tabTrigger)
      }
      tabTrigger.addEventListener('click', () => {
        setTabActive(tabsParent, tabTrigger.getAttribute('data-tab'))
        adjustIndicator(tabsParent, tabTrigger)
      })
    })
  })

  activateTabByHash()
})

window.addEventListener('hashchange', activateTabByHash)
