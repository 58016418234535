import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import Qs from 'qs'
import axios from 'axios'

import 'virtual:svg-icons-register'

import './App.scss'

import '../js/footer'
import '../js/header'
import '../js/contact-overlay'
import '../js/headline-text'
import '../js/max-lines'
import '../js/collapse'
import '../js/tabs'
import '../js/lightbox'
import '../js/swiper'
import '../js/carousel-cards'
import '../js/image-carousel'
import '../js/parallax'
import '../js/teaser-narrow-width'
import '../js/image-switch'
import '../js/video'
import '../js/region-prompt'
import '../js/success'
import '../js/anchor-links'
import '../js/cookie-banner'

import { QueryParamProvider } from 'use-query-params'
import { WindowHistoryAdapter } from 'use-query-params/adapters/window'
import { saveReferrer } from '../js/components/forms/helpers'
import { QueryClient, QueryClientProvider } from 'react-query'

saveReferrer()

axios.defaults.paramsSerializer = (params) =>
  Qs.stringify(params, { arrayFormat: 'repeat' })

const reactComponents = [
  {
    selector: '#form-rent-enquiry-simple',
    component: 'forms/rent-enquiry-simple/index',
  },
  {
    selector: '#form-general-enquiry',
    component: 'forms/general-enquiry/index',
  },
  {
    selector: '#form-internal-enquiry',
    component: 'forms/internal-enquiry/index',
  },
  {
    selector: '#form-offer-enquiry',
    component: 'forms/offer-enquiry/index',
    props: { type: 'rental' },
  },
  {
    selector: '#form-offer-purchase',
    component: 'forms/offer-enquiry/index',
    props: { type: 'purchase' },
  },
  {
    selector: '#form-application-areas',
    component: 'forms/application-area/index',
  },
  {
    selector: '#form-rent-enquiry-complex',
    component: 'forms/rent-enquiry-complex/index',
  },
  {
    selector: '#form-reference-project',
    component: 'forms/reference-project/index',
  },
  {
    selector: '#form-planning-sample',
    component: 'forms/planning-sample/index',
  },
  {
    selector: '#s-form-abo',
    component: 'standard-forms/abo',
  },
  {
    selector: '#s-form-repurchase',
    component: 'standard-forms/repurchase',
  },
  {
    selector: '#s-form-defects',
    component: 'standard-forms/defects',
  },
  {
    selector: '#s-form-subcontractor',
    component: 'standard-forms/subcontractor',
  },
  {
    selector: '#s-form-newsletter',
    component: 'standard-forms/newsletter',
  },
  {
    selector: '#s-form-return',
    component: 'standard-forms/return',
  },
  {
    selector: '#s-form-callback',
    component: 'standard-forms/callback',
  },
  {
    selector: '#s-form-partner',
    component: 'standard-forms/partner',
  },
  {
    selector: '#s-form-employee-application',
    component:  'standard-forms/employee-application',
  },
  {
    selector: '#s-form-general-contact',
    component: 'standard-forms/general-contact-form',
  },
  {
    selector: '#stream',
    component: 'stream/index',
  },
  {
    selector: '#reference-projects-overview',
    component: 'reference-projects/overview',
  },
  {
    selector: '#planning-samples',
    component: 'planning-samples/overview',
  },
  {
    selector: '.reference-slider-comp',
    component: 'reference-projects/slider',
  },
  {
    selector: '#offer-overview',
    component: 'offers/overview',
  },
  {
    selector: '#offer-details',
    component: 'offers/detail',
  },
  {
    selector: '#news-overview',
    component: 'news/index',
  },
  {
    selector: '.offers-slider-comp',
    component: 'offers/slider',
  },
  {
    selector: '.Search',
    component: 'search',
  },
  {
    selector: '#depot-map',
    component: 'depot-map/depot-map',
    props: { isDepotMap: true }
  },
  {
    selector: '#showroom-map',
    component: 'depot-map/depot-map',
    props: { isDepotMap: false }
  },
  {
    selector: '[data-success-page-contact]',
    component: 'forms/common/success-contact',
  },
]

reactComponents.forEach((reactComponent) => {
  const splitComponentPath = reactComponent.component.split('/') 
  let ReactComponent
  if(splitComponentPath.length === 1) {
    ReactComponent = React.lazy(() => import(`../js/components/${splitComponentPath[0]}.tsx`))
  }
  if(splitComponentPath.length === 2) {
    ReactComponent = React.lazy(() => import(`../js/components/${splitComponentPath[0]}/${splitComponentPath[1]}.tsx`))
  }
  if(splitComponentPath.length === 3) {
    ReactComponent = React.lazy(() => import(`../js/components/${splitComponentPath[0]}/${splitComponentPath[1]}/${splitComponentPath[2]}.tsx`))
  }
  if(splitComponentPath.length === 4) {
    ReactComponent = React.lazy(() => import(`../js/components/${splitComponentPath[0]}/${splitComponentPath[1]}/${splitComponentPath[2]}/${splitComponentPath[3]}.tsx`))
  }
  const props = reactComponent.props || {}
  const queryClient = new QueryClient()

  document.querySelectorAll(reactComponent.selector).forEach((element) => {
  const root = createRoot(element)
    root.render(
      <QueryClientProvider client={queryClient}>
        <QueryParamProvider adapter={WindowHistoryAdapter}>
          <Suspense fallback={<div></div>}>
            {/* @ts-ignore */}
            <ReactComponent {...props} />
          </ Suspense>
        </QueryParamProvider>
      </QueryClientProvider>
    )
  })
})
