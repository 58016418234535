document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.CarouselCards').forEach( async (module) => {
    const { tns } = await import('tiny-slider/src/tiny-slider')
    const container = module.querySelector('.CarouselCards__swiper')!
    tns({
      container,
      preventScrollOnTouch: 'auto',
      items: 1,
      fixedWidth: 270,
      loop: true,
      swipeAngle: 30,
      controls: false,
      autoplay: false,
      prevButton: module.querySelector('.CarouselCards__prev') as HTMLElement,
      nextButton: module.querySelector('.CarouselCards__next') as HTMLElement,
      nav: true,
      navPosition: 'bottom',
      responsive: {
        340: {
          fixedWidth: 320,
        },
        450: {
          fixedWidth: 420,
        },
        600: {
          items: 2,
          nav: false,
          controls: true,
          fixedWidth: false,
        },
        900: {
          items: 3,
          nav: false,
          controls: true,
        },
      },
    })
  })

  document.querySelectorAll('.CarouselCards__read-more').forEach((button) => {
    button.addEventListener('click', () => {
      const surroundingElem = button.closest('.CarouselCards')!
      const fade = surroundingElem.querySelector(
        '.CarouselCards__wrapper--faded',
      )
      fade?.classList.remove('CarouselCards__wrapper--faded')
      button.remove()
    })
  })
})
