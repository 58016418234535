import 'lite-youtube-embed'
import 'lite-youtube-embed/src/lite-yt-embed.css'

document.addEventListener('DOMContentLoaded', () => {
  const urlHash = window.location.hash

  if (urlHash) {
    const videoContainer = document.querySelector(
      `#video_${urlHash.split('#')[1]}`,
    )!
    if (!videoContainer) {
      return
    }

    const yOffset = -100
    const y = videoContainer.getBoundingClientRect().top + yOffset

    const lightbox = videoContainer?.querySelector('.Video__lightbox')!

    const lightboxIframe = lightbox.querySelector('iframe[data-src]')
    if (lightboxIframe) {
      const src = lightboxIframe.getAttribute('data-src')
      lightboxIframe.setAttribute('src', src || '')
    }
    lightbox.classList.add('Video__lightbox--active')

    videoContainer
      ?.querySelector('.Video__lightbox__close')!
      .addEventListener('click', () => {
        lightbox.classList.remove('Video__lightbox--active')
      })

    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  document.querySelectorAll('.Video').forEach((video) => {
    const play = video.querySelector('.Video__play-button')! as HTMLElement
    const thumb = video.querySelector('.Video__thumbnail')! as HTMLElement
    play?.addEventListener('click', () => {
      play.style.display = 'none'
      thumb.style.display = 'none'
      const iframe = video.querySelector('iframe')
      if (iframe) {
        iframe.src += '&autoplay=1'
      }
    })
  })
})
