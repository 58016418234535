document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.TeaserNarrowWidth__inner').forEach(async (teaser) => {
    teaser
      .querySelector('.TeaserNarrowWidth__text')!
      .addEventListener('mouseenter', () =>
        teaser.classList.add('TeaserNarrowWidth__inner--hover'),
      )
    teaser
      .querySelector('.TeaserNarrowWidth__text')!
      .addEventListener('mouseleave', () =>
        teaser.classList.remove('TeaserNarrowWidth__inner--hover'),
      )

      const slider = teaser.querySelector('.beer-slider');
      if (slider) {
        const BeerSlider = await import('beerslider')
        new BeerSlider(slider)
      }
  })
})
