document.addEventListener('DOMContentLoaded', () => {
	activateInnerCarouselCards();
	document.addEventListener('click', event => {
		if((event.target as HTMLElement)?.classList?.contains('CarouselCards_prev' || 'CarouselCards__next')) {
			activateInnerCarouselCards();
		}
	})
})

async function activateInnerCarouselCards() {
	await import('lightgallery.js')
	await import('lg-thumbnail.js')
	const { tns } = await import('tiny-slider/src/tiny-slider')
	document.querySelectorAll('.Image-Gallery').forEach(module => {
		const container = module.querySelector('.Image-Gallery__swiper')!
		window.lightGallery(module, {
			thumbnail: true,
			selector: 'a[href]',
			exThumbImage: 'data-thumb-img',
		})
		tns({
			container,
			preventScrollOnTouch: 'auto',
			items: 1,
			fixedWidth: false,
			loop: true,
			swipeAngle: 30,
			controls: false,
			autoplay: false,
			prevButton: module.querySelector('.Image-Gallery__prev') as HTMLElement,
			nextButton: module.querySelector('.Image-Gallery__next') as HTMLElement,
			navContainer: module.querySelector('.Image-Gallery__nav-container') as HTMLElement,
			nav: true,
			navAsThumbnails: true,
			navPosition: 'bottom',
			responsive: {
				600: {
					nav: true,
					controls: true,
					fixedWidth: false,
				}
			}
		})
	})
}
	
